html, body, main {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.content {
  display: none;
}

@media (min-width: 768px) {
  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;
  }

  .grid {
    pointer-events: none;
    grid-template-rows: repeat(50, 2%);
    grid-template-columns: repeat(50, 2%);
    width: 110%;
    height: 110%;
    display: grid;
    position: absolute;
    top: -5%;
    left: -5%;
  }

  .grid__item {
    position: relative;
  }

  .grid--img .grid__item {
    will-change: transform;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  .grid__item-img {
    background-position: 50%;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .grid--img .grid__item-img {
    will-change: transform;
    flex: none;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
  }

  .pos-1 {
    grid-area: 10 / 1 / 26 / 7;
  }

  .pos-2 {
    grid-area: 1 / 18 / 9 / 27;
  }

  .pos-3 {
    grid-area: 1 / 36 / 14 / 42;
  }

  .pos-4 {
    grid-area: 13 / 11 / 32 / 18;
  }

  .pos-5 {
    grid-area: 17 / 32 / 32 / 38;
  }

  .pos-6 {
    grid-area: 20 / 46 / 28 / 51;
  }

  .pos-7 {
    grid-area: 43 / 1 / 51 / 10;
  }

  .pos-8 {
    grid-area: 38 / 14 / 46 / 22;
  }

  .pos-9 {
    grid-area: 40 / 26 / 51 / 32;
  }

  .pos-10 {
    grid-area: 37 / 39 / 48 / 47;
  }

  .content__title {
    margin: 0;
    font-family: bely-display, sans-serif;
    font-size: 8vw;
    font-weight: 400;
    line-height: .66;
    position: relative;
  }

  .content__title-sub {
    color: var(--color-content-title-sub);
    margin-top: 3vw;
    margin-left: 6vw;
    font-size: 7vw;
    line-height: .66;
    display: block;
  }

  .content__title-sub:nth-of-type(3) {
    margin-left: 12vw;
  }

  .content__title-sub:nth-of-type(4) {
    margin-top: 4vw;
    margin-left: 18vw;
    font-size: 3vw;
  }

  .cursor {
    display: none;
  }

  @media screen and (min-width: 53em) {
    .message {
      display: none;
    }

    .frame {
      text-align: left;
      z-index: 100;
      pointer-events: none;
      grid-template: "title links"
                     ". ."
                     ". demos"
                     / 75% 25%;
      align-content: space-between;
      width: 100%;
      max-width: none;
      height: 100%;
      padding: 3rem;
      display: grid;
      position: fixed;
      top: 0;
      left: 0;
    }

    .frame__title-wrap {
      grid-area: title;
      display: flex;
    }

    .frame__title {
      margin: 0;
    }

    .frame__tagline {
      opacity: .5;
      margin: 0 0 0 1rem;
      padding: 0 0 0 1rem;
      position: relative;
    }

    .frame__demos {
      grid-area: demos;
      justify-self: end;
      margin: 0;
    }

    .frame__links {
      grid-area: links;
      justify-self: end;
      padding: 0;
    }

    .frame a {
      pointer-events: auto;
    }

    .content {
      justify-content: center;
      height: 100%;
      max-height: none;
    }
  }

  .home-mobile-wrapper {
    display: none;
  }
}

.home-mobile-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  display: flex;
}

.home-mobile-title {
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
}

.home-mobile-title__link {
  opacity: 0;
  font-family: bely-display, sans-serif;
}

/*# sourceMappingURL=index.56621534.css.map */
