html,
body,
main {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.content {
  display: none;
}

@media (min-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    /* height: calc(100vh - 13rem); */
    position: relative;
    align-items: center;
    justify-content: center;
    /* max-height: 300px; */
  }

  .grid {
    pointer-events: none;
    position: absolute;
    width: 110%;
    height: 110%;
    top: -5%;
    left: -5%;
    display: grid;
    grid-template-columns: repeat(50, 2%);
    grid-template-rows: repeat(50, 2%);
  }

  .grid__item {
    position: relative;
  }

  .grid--img .grid__item {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;
  }

  .grid__item-img {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }

  .grid--img .grid__item-img {
    flex: none;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    will-change: transform;
  }

  /* Shorthand grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end */

  .pos-1 {
    grid-area: 10 / 1 / 26 / 7;
  }

  .pos-2 {
    grid-area: 1 / 18 / 9 / 27;
  }

  .pos-3 {
    grid-area: 1 / 36 / 14 / 42;
  }

  .pos-4 {
    grid-area: 13 / 11 / 32 / 18;
  }

  .pos-5 {
    grid-area: 17 / 32 / 32 / 38;
  }

  .pos-6 {
    grid-area: 20 / 46 / 28 / 51;
  }

  .pos-7 {
    grid-area: 43 / 1 / 51 / 10;
  }

  .pos-8 {
    grid-area: 38 / 14 / 46 / 22;
  }

  .pos-9 {
    grid-area: 40 / 26 / 51 / 32;
  }

  .pos-10 {
    grid-area: 37 / 39 / 48 / 47;
  }

  .content__title {
    font-family: bely-display, sans-serif;
    font-weight: 400;
    font-size: 8vw;
    margin: 0;
    line-height: 0.66;
    position: relative;
  }

  .content__title-sub {
    color: var(--color-content-title-sub);
    font-size: 7vw;
    display: block;
    margin-left: 6vw;
    margin-top: 3vw;
    line-height: 0.66;
  }

  .content__title-sub:nth-of-type(3) {
    margin-left: 12vw;
  }

  .content__title-sub:nth-of-type(4) {
    margin-top: 4vw;
    margin-left: 18vw;
    font-size: 3vw;
  }

  .cursor {
    display: none;
  }

  @media screen and (min-width: 53em) {
    .message {
      display: none;
    }
    .frame {
      position: fixed;
      text-align: left;
      z-index: 100;
      top: 0;
      left: 0;
      display: grid;
      align-content: space-between;
      width: 100%;
      max-width: none;
      height: 100%;
      padding: 3rem;
      pointer-events: none;
      grid-template-columns: 75% 25%;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "title links"
        "... ..."
        "... demos";
    }
    .frame__title-wrap {
      grid-area: title;
      display: flex;
    }
    .frame__title {
      margin: 0;
    }
    .frame__tagline {
      position: relative;
      margin: 0 0 0 1rem;
      padding: 0 0 0 1rem;
      opacity: 0.5;
    }
    .frame__demos {
      margin: 0;
      grid-area: demos;
      justify-self: end;
    }
    .frame__links {
      grid-area: links;
      padding: 0;
      justify-self: end;
    }
    .frame a {
      pointer-events: auto;
    }
    .content {
      height: 100%;
      justify-content: center;
      max-height: none;
    }
  }
}

@media (min-width: 768px) {
  .home-mobile-wrapper {
    display: none;
  }
}
.home-mobile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
}
.home-mobile-title {
  width: 100%;
  height: auto;
  margin-bottom: 4rem;
}
.home-mobile-title__link {
  opacity: 0;
  font-family: bely-display, sans-serif;
}
